<template>
  <div class="choose-municipality">
    <div class="choose-municipality__wrapper">
      <Row class="choose-municipality__row">
        <Column class="choose-municipality__select">
          <p class="choose-municipality__title">Выберите населенный пункт</p>
          <ul class="choose-municipality__list">
            <li :key="city.id"
                class="choose-municipality__list-item"
                v-for="city in cities">
              <router-link :to="`/municipality/${city.slug}`"
                           class="choose-municipality__link">{{city.name}}
              </router-link>
            </li>
          </ul>
          <Button
            class="choose-municipality__mobile-button"
            v-on:click.native="toRegion"
            v-if="$viewport.mobile"
            color="green"
            full
          >Об области</Button>
        </Column>
        <Column class="choose-municipality__information"
                v-if="$viewport.desktop">
          <p class="choose-municipality__title">Об области</p>
          <p class="choose-municipality__description">Край, богатый природными чудесами и красотами.
            Узнай все секреты Томской области.</p>
          <Button class="choose-municipality__button"
                  v-on:click.native="toRegion"
                  color="transparent">Подробнее
          </Button>
        </Column>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChooseMunicipality',
  computed: {
    cities() {
      return this.$cities.data.filter(item => item.slug !== this.$route.params.municipality);
    },
  },
  methods: {
    closePopup() {
      this.$store.commit('CLOSE_POPUP');
    },
    toRegion() {
      this.$router.push('/municipality/region');
      this.closePopup();
    },
  },
};
</script>
